/* global ga */
$(document).ready(function() {
	$("form").each(function(index,element){
		$(element).validate();
	});
	$("form").addClass("infield-active");
	$(".infield").inFieldLabels();
    
    
	/***** Internal anchor link click tracking *****/
	$("a[href*=\"#\"]:not([href=\"#\"])").click(function() {
		var thelink = this;
		if (location.pathname.replace(/^\//,"") == this.pathname.replace(/^\//,"") && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $("[name=" + this.hash.slice(1) +"]");
			if (target.length) {
				if (ga) ga("send", "pageview", {"page": thelink.hash,"title": thelink.hash.slice(1)+" - Scroll"});
			}
			
		}
	});
    
	setupSlideMenu();
});
export function setupSlideMenu(){

	$("#mobile-menu, header nav ul").click(function(){
		$("body").toggleClass("slide-menu-active");
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}
